import React, { useState, useEffect } from "react";
import { Box, Text } from "primitives";
import { Table, TableHead, TableBody, TableRow, TableCell } from "components";
import { PaginationSimple } from "app/shared";
import { useSelector, useDispatch } from "react-redux";
import { Candidate } from "app/passageManager/models";
import { getCandidateStatus, Status } from "./Status";
import { BookCandidateContainer } from "../BookCandidate/BookCandidate";
import { CancelPassage } from "../BookCandidate/CancelPassage";
import { syncBookedAndAvailablePassagesAction } from "app/passageManager/actions";
import { paginate } from "app/passageManager/utils";

export const CandidatesTable = () => {
  const dispatch = useDispatch();
  const { config, allPassages, daterange } = useSelector(
    (s: any) => s.passageManager
  );
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(allPassages?.length || 0);

  useEffect(() => {
    const from = daterange?.startTime
      ? new Date(daterange?.startTime)
      : new Date();
    const to = daterange?.endTime
      ? new Date(daterange?.endTime)
      : new Date(new Date().setMonth(new Date().getMonth() + 2));
    const satIds = Object.keys(config?.groundStationsBySatelliteIDs || {}).map(
      Number
    );
    dispatch(
      syncBookedAndAvailablePassagesAction(satIds, from, to, config?.id)
    );
  }, [daterange?.startTime, daterange?.endTime]);

  useEffect(() => {
    setTotal(allPassages?.length || 0);
  }, [allPassages?.length || 0]);

  return (
    <Box
      data-testid="passage-manager-list"
      className="passage-manager-list"
      mx={3}
    >
      {config && (
        <Text m={1} fontSize={5} bold color="text.default">
          {" "}
          CANDIDATES FETCHED USING CONFIG: {config?.name}{" "}
        </Text>
      )}
      <Table style={{ tableLayout: "fixed" }}>
        <TableHead>
          <TableRow bg="fill.0">
            <TableCell width={"3%"}>C.ID</TableCell>
            <TableCell width={"11.5%"} align="center">
              START
            </TableCell>
            <TableCell width={"11.5%"} align="center">
              END
            </TableCell>
            <TableCell width={"6%"} align="center">
              GS
            </TableCell>
            <TableCell width={"5%"} align="center">
              MAX EL
            </TableCell>
            <TableCell width={"10%"} align="center">
              SAT
            </TableCell>
            <TableCell width={"8%"} align="center">
              PROVIDER
            </TableCell>
            <TableCell width={"10%"} align="center">
              BOOKED BY
            </TableCell>
            <TableCell width={"10%"} align="center">
              BOOKED AT
            </TableCell>
            <TableCell width={"8%"} align="center">
              STATUS
            </TableCell>
            <TableCell width={"8%"} align="center"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Array.isArray(allPassages) &&
            allPassages
              .slice(...paginate(page, pageSize))
              .map((candidate: Candidate, idx: number) => {
                const { bookable, status } = getCandidateStatus(candidate);
                return (
                  <TableRow key={candidate.passageID}>
                    <TableCell>
                      {candidate?.additionalInfo?.bookingConfigId}
                    </TableCell>
                    <TableCell>
                      {new Date(candidate.aos).toLocaleString()}
                    </TableCell>
                    <TableCell>
                      {new Date(candidate.los).toLocaleString()}
                    </TableCell>
                    <TableCell>{candidate.groundStationName}</TableCell>
                    <TableCell>{candidate.maxElevation}</TableCell>
                    <TableCell align="center">
                      {candidate.satellite || candidate?.satelliteID}
                    </TableCell>
                    <TableCell align="center">
                      {candidate.provider ||
                        candidate?.additionalInfo?.provider?.providerName}
                    </TableCell>
                    <TableCell align="center">
                      {candidate?.additionalInfo?.bookedBy}
                    </TableCell>
                    <TableCell align="center">
                      {candidate?.additionalInfo?.bookedAt &&
                        new Date(
                          candidate?.additionalInfo.bookedAt
                        ).toLocaleString()}
                    </TableCell>
                    <TableCell align="center">
                      <Status status={status} bookable={bookable} />
                    </TableCell>
                    <TableCell align="center">
                      {status !== "BOOKED" ? (
                        <BookCandidateContainer
                          candidate={candidate}
                          bookingConfigId={config?.id}
                          bookable={bookable}
                        />
                      ) : (
                        <CancelPassage candidate={candidate} config={config} />
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
        </TableBody>
      </Table>
      <PaginationSimple
        total={total}
        page={page + 1}
        pageSize={pageSize}
        nextPageUrl={(page + 1) * pageSize < total ? page + 1 : 0}
        previousPageUrl={page - 1}
        onChange={(newPage: string | number) => {
          setPage(Number(newPage));
          setPageSize(pageSize);
        }}
        onPageSizeChange={(newPageSize: number) => {
          setPage(0);
          setPageSize(Number(newPageSize));
        }}
      />
    </Box>
  );
};
