import React, { useState } from "react";
import { Form } from "app/shared";
import { Box } from "primitives";
import { SuspenseMutation } from "app/network";
import { Heading, Flex, Text } from "primitives";
import { CancelButton, SaveButton } from "components";
import { useDispatch, useSelector } from "react-redux";
import { isValidJSON } from "app/dashboard/utils/transformations";
import { setFeedback } from "app/feedback/actions";
import { FeedbackStatus } from "app/feedback/models";
import {
  createBookerConfig,
  updateBookerConfig
} from "app/passageManager/actions";
import { schemaImport, transformPayload, uiSchemaImport } from "./helpers";

export const BookingConfigImport = (props: any) => {
  const dispatch = useDispatch();
  const {
    bookerConfigs: { data: configs }
  } = useSelector((s: any) => s.passageManager);
  const [formData, setFormData] = useState({});
  const sats = useSelector((s: any) => s.constellations?.dashboard);
  const submit = async (
    e: React.FormEvent<HTMLInputElement>,
    formData: any
  ) => {
    e.preventDefault();
    try {
      const imported = isValidJSON(formData.file) && JSON.parse(formData.file);
      const formattedConfigs = imported?.bookingConfigs.map((c: any) =>
        transformPayload(c, sats)
      );
      for (const id in formattedConfigs) {
        if (id) {
          const config = formattedConfigs[id];
          const existingConfig = configs.filter(
            (d: any) => d.name == config.name
          );
          const existingConfigID = existingConfig[0]?.id;
          if (existingConfigID) {
            const data = await dispatch(
              updateBookerConfig(existingConfigID, {
                id: existingConfigID,
                ...config
              })
            );
            !data &&
              dispatch(
                setFeedback(
                  `${config.name} updated successfully`,
                  FeedbackStatus.SUCCESS
                )
              );
          } else {
            const data = await dispatch(createBookerConfig(config));
            !data &&
              dispatch(
                setFeedback(
                  `${config?.name} created successfully`,
                  FeedbackStatus.SUCCESS
                )
              );
          }
        }
      }
    } catch (e: any) {
      dispatch(
        setFeedback(`Import failed`, FeedbackStatus.ERROR, e.toString())
      );
    } finally {
      props.history.push("/passage-manager");
    }
  };

  return (
    <Box color="text.default" data-testid="passage-manager-export" mx={3}>
      <Flex mb={2}>
        <Heading display={1}>Passage Manager Export</Heading>
      </Flex>
      <Flex flexDirection="column" bg="fill.0" p={3}>
        <SuspenseMutation>
          {({ loading, error, action }) => {
            return (
              <Form
                formData={formData}
                schema={schemaImport()}
                uiSchema={uiSchemaImport}
                disabled={loading}
                onSubmit={(
                  form: { formData: any },
                  e: React.FormEvent<HTMLInputElement>
                ) => action(() => submit(e, form.formData))}
                onChange={(form: any) => () =>
                  setFormData({ formData: form.formData })
                }
              >
                <CancelButton
                  onClick={() => props.history.push("/passage-manager")}
                  mr={2}
                  mt={4}
                >
                  Cancel
                </CancelButton>

                <SaveButton ml={2} mt={4} type="submit" disabled={loading}>
                  Import
                </SaveButton>

                {error ? (
                  <Box my={2}>
                    <Text color="text.danger">
                      Error...{console.log(error)}
                    </Text>
                  </Box>
                ) : null}
              </Form>
            );
          }}
        </SuspenseMutation>
      </Flex>
    </Box>
  );
};
