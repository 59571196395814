import { FileWidget } from "app/shared/form";
import { SelectBox } from "app/shared";
import {
  BookerConfiguration,
  Satellite,
  SatelliteMap
} from "app/passageManager/models";

export const createExportableConfigs = (configs: any[], configName: string) => {
  if (!Array.isArray(configs) || !configName) return null;
  const exportablePayload =
    configName === "ALL"
      ? configs
      : configs.filter((b: any) => b.name === configName);
  const removeIDs = exportablePayload.map(
    ({ id, groundStationsBySatelliteIDs, ...e }) => ({ ...e })
  );
  return { bookingConfigs: removeIDs };
};

export const makeSatNameIdMap = (sats: Satellite[]): SatelliteMap => {
  return sats
    .map((sat) => ({
      id: sat.id,
      name: sat.label
    }))
    .reduce<SatelliteMap>((map, i) => {
      map[i.name] = i.id;
      return map;
    }, {});
};

export const transformPayload = (
  config: BookerConfiguration,
  sats: Satellite[]
) => {
  const satNameIDMap = makeSatNameIdMap(sats);

  const entries = Object.entries(config.groundStationsBySatellite);
  const filteredEntries = entries.filter(([name]) => {
    return satNameIDMap[name] !== undefined;
  });

  const namesToIDsMapped = filteredEntries.map(([name, groundstations]) => {
    const satelliteId = satNameIDMap[name].toString();
    const groundStationIds = (groundstations as any[]).map(
      (gs: any) => gs.groundStationID
    );
    return [satelliteId, groundStationIds];
  });

  const withIDs = Object.fromEntries(namesToIDsMapped);

  const formatted = {
    ...config,
    groundStationsBySatellite: withIDs
  };
  return formatted;
};

export const schemaImport = () => ({
  type: "object",
  properties: {
    file: {
      title: " ",
      type: "string"
    }
  }
});

export const uiSchemaImport = {
  file: {
    "ui:widget": FileWidget
  }
};

export const uiSchemaExport = {
  bookerConfigName: { "ui:widget": SelectBox }
};

type BookingConfig = any;
export const schemaExport = (bookerConfigs: BookingConfig[]) => {
  return {
    type: "object",
    properties: {
      bookerConfigName: {
        type: "string",
        title: "BookingConfigs",
        enum: [
          "ALL",
          ...(bookerConfigs?.map((d: BookingConfig) => d.name) || [])
        ],
        enumNames: [
          "ALL",
          ...(bookerConfigs?.map((d: BookingConfig) => d.name) || [])
        ]
      }
    }
  };
};
