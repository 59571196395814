import { groundStationDataProvider } from "app/network/dataProvider";
import { OperationType } from "app/network/models/constants";
import { GroundStation, GroundStationConfiguration, Provider } from "../models";

export const fetchGroundStation = async (
  id: number
): Promise<GroundStation> => {
  const response: any = await groundStationDataProvider(
    OperationType.GET_ONE,
    `groundStations`,
    { id }
  );
  return response.data;
};

export const fetchGroundStations = async (): Promise<GroundStation[]> => {
  try {
    const response: any = await groundStationDataProvider(
      OperationType.GET_LIST,
      `groundStations`
    );
    return response.data;
  } catch (e) {
    return [];
  }
};

export const fetchGroundStationConfigList = async (): Promise<
  GroundStationConfiguration[]
> => {
  try {
    const response: any = await groundStationDataProvider(
      OperationType.GET_LIST,
      `configurations`
    );
    return response.data;
  } catch (e) {
    return [];
  }
};

export const fetchGroundStationConfig = async (
  satelliteID: number,
  groundStationID: number
): Promise<GroundStationConfiguration> => {
  const response: any = await groundStationDataProvider(
    OperationType.GET_LIST,
    `configurations/${satelliteID}/${groundStationID}`
  );
  return response.data;
};

export const createGroundStationConfig = async (
  data: GroundStationConfiguration
): Promise<GroundStationConfiguration> => {
  const { groundStationID, satelliteID } = data;
  const response: any = await groundStationDataProvider(
    OperationType.UPDATE_ONE,
    `configurations/${satelliteID}/${groundStationID}`,
    { data }
  );
  return response.data;
};

export const deleteGroundStationConfig = async (
  satelliteID: number,
  groundStationID: number
): Promise<any> => {
  await groundStationDataProvider(
    OperationType.DELETE,
    `configurations/${satelliteID}/${groundStationID}`
  );
};

export const fetchGroundStationBySatellite = async (
  satelliteID: number
): Promise<GroundStation[]> => {
  try {
    const response: any = await groundStationDataProvider(
      OperationType.GET_LIST,
      `satellites/${satelliteID}/groundStations`
    );
    return response.data;
  } catch (e) {
    return [];
  }
};

export const fetchProviders = async (): Promise<Provider[]> => {
  try {
    const response: any = await groundStationDataProvider(
      OperationType.GET_LIST,
      `providers`
    );
    return response.data;
  } catch (e) {
    return [];
  }
};
