import { GET_LIST } from "app/network";
import { passageManagerDataProvider } from "app/network/dataProvider";

// TODO: until fix past date issue is fixed on LEAF/BE
const TWO_HOURS_IN_MS = 2 * 60 * 60 * 1000;
const THREE_WEEKS_IN_MS = 3 * 7 * 24 * 60 * 60 * 1000;

export const defaultStartTime = new Date(
  Date.now() + TWO_HOURS_IN_MS
).toISOString();
const defaultEndTime = new Date(Date.now() + THREE_WEEKS_IN_MS).toISOString();

export const getPassageCandidatesForConfig = (
  configId: any,
  startTime?: string,
  endTime?: string
) => {
  if (!configId) return { data: [] };
  const effectiveStartTime =
    startTime && new Date(startTime) > new Date()
      ? startTime
      : defaultStartTime;

  const effectiveEndTime =
    endTime && new Date(endTime) > new Date() ? endTime : defaultEndTime;

  const params = new URLSearchParams({
    availability: "available",
    bookingConfigurationID: configId?.toString(),
    startTime: effectiveStartTime,
    endTime: effectiveEndTime
  });

  const query = `candidates?${params.toString()}`;

  return passageManagerDataProvider(GET_LIST, query);
};
