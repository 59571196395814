import styled from "styled-components";
import { TimelinItemTooltipProps } from "app/planningSystem/models";

export const TimelineTooltip = styled("div")<TimelinItemTooltipProps>`
  position: fixed;
  top: ${(props) => `${props.coord.y}px` || "0px"};
  left: ${(props) => `${props.coord.x}px` || "0px"};
  color: #fff;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 12px;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.6);
`;
