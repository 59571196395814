import React, { useCallback, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Flex } from "primitives";
import { Form } from "app/shared";
import { createBookerConfig } from "app/passageManager/actions";
import { resetConfigFormData, transformFormData, validator } from "app/passageManager/utils";
import { bookerConfigSchemaCreate, bookerConfigUISchema } from "./configSchema";

export const ConfigCreate = (props: any) => {
  const type = props.type;
  const dispatch = useDispatch();
  const [form, setForm] = useState({})
  const satellites = useSelector((s: any) => s.constellations.dashboard);
  const {
    groundStationConfigs,
    bookerConfigs: { data: passageManagerConfigs }
  } = useSelector((s: any) => s.passageManager);

  const submit = async (
    e: React.FormEvent<HTMLInputElement>,
    formData: any
  ) => {
    e.preventDefault();
    const formattedData = transformFormData(
      formData,
      satellites,
      groundStationConfigs
    );
    const errorResponse: any = await dispatch(createBookerConfig(formattedData));
    if (errorResponse) {
      const updatedForm = resetConfigFormData(formData, errorResponse)
      setForm(updatedForm)
    }
    !errorResponse && props.closeModal();
  };

  const transformData = (formData: any): any => {
    // add custom transforms on need basis
    return formData;
  };

  const onChange = useCallback((form) => {
    transformData(form.formData);
  }, []);


  return (
    <Flex flexDirection="column" bg="fill.0" p={5}>
      <Form
        noValidate
        ref={props.formRef}
        id="passage-manager"
        validate={(formData: any, errors: any) => validator(formData, errors)}
        formData={form}
        schema={bookerConfigSchemaCreate(
          type,
          passageManagerConfigs,
          groundStationConfigs
        )}
        uiSchema={bookerConfigUISchema}
        disabled={false}
        onSubmit={(
          form: { formData: any },
          e: React.FormEvent<HTMLInputElement>
        ) => {
          return submit(e, form.formData);
        }}
        onChange={(form: any) => onChange(form)}
      >
        <></>
      </Form>
    </Flex>
  );
};
