import React, { useContext, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import {
  TimelineItemRendererProps,
  ContextActionsNames,
  ITimeline,
  TimelineEntryStatus
} from "app/planningSystem/models";
import { PlanningSystemContext } from "app/planningSystem/context";
import { useContextMenu } from "hooks";
import { TimelineItemContextMenu } from "./TimelineItemContextMenu";
import { TimelineEntryTypeColor } from "app/planningSystem/utils/constants";

export const TimelineItemRenderer = ({
  item,
  itemContext,
  getItemProps,
  getResizeProps,
  selectedCalendarTimeline,
  handleItemDelete
}: TimelineItemRendererProps) => {
  const { dispatch } = useContext(PlanningSystemContext);
  const { contextMenu, handleContextMenu } = useContextMenu({
    data: item,
    menuTemplate: (props) => (
      <TimelineItemContextMenu {...props} handleItemDelete={handleItemDelete} />
    )
  });

  const selectedTimeline: ITimeline = useSelector((s: any) =>
    s.planningSystem.timelines.timelines.find(
      (t: ITimeline) => t.uuid === selectedCalendarTimeline?.uuid
    )
  );

  useEffect(() => {
    dispatch({ type: ContextActionsNames.contextMenu, payload: contextMenu });
  }, [contextMenu]);

  const { left: leftResizeProps, right: rightResizeProps } = getResizeProps();
  const itemProps = { ...getItemProps(item.itemProps) };

  /**
   * Getting the `TimelineEntry` from the ITimelineEntry by id
   */
  const entry = useMemo(() => {
    return selectedTimeline?.entries.find(
      (e) => `${e.oaUUID}-${e.timelineEntryType}` === item.id
    );
  }, [item.id]);

  const getEntryColor = () => {
    return (
      (itemContext.selected && TimelineEntryTypeColor.SELECTED) ||
      (entry?.status === TimelineEntryStatus.CONFLICT &&
        TimelineEntryTypeColor.CONFLICT) ||
      (entry?.timelineEntryType &&
        TimelineEntryTypeColor[entry?.timelineEntryType]) ||
      "yellow"
    );
  };

  const itemStyles = {
    ...itemProps.style,
    background: getEntryColor()
  };

  return (
    <div {...{ ...itemProps, style: { ...itemStyles } }}>
      {itemContext.useResizeHandle ? (
        <div
          {...leftResizeProps}
          style={{
            ...leftResizeProps.style,
            cursor: (item.canResize && "w-resize") || "pointer",
            width: "1px"
          }}
        />
      ) : (
        ""
      )}

      <div
        onContextMenu={handleContextMenu}
        className="rct-item-content"
        style={{
          maxHeight: `${itemContext.dimensions.height}`,
          cursor: (item.canMove && "move") || "pointer",
          width: "100%"
        }}
      >
        {itemContext.title}
      </div>

      {itemContext.useResizeHandle ? (
        <div
          {...rightResizeProps}
          style={{
            ...rightResizeProps.style,
            cursor: (item.canResize && "e-resize") || "pointer",
            width: "1px"
          }}
        />
      ) : (
        ""
      )}
    </div>
  );
};
