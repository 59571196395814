import { Dispatch, MouseEventHandler } from "react";
import {
  TimelineItemBase,
  TimelineGroup as TimelineGrp
} from "react-calendar-timeline";
import { ThunkAction } from "redux-thunk";
import { SatelliteInstance } from "app/satellite/models";
import { Passage } from "app/visibilityWindow/models";
import { Procedure } from "app/procedure/models";
import { Resource } from "app/resources/models";
import { Id } from "react-calendar-timeline";
export type { Passage } from "app/visibilityWindow/models";

/**
 * Data models
 */
export interface Oa {
  name: string;
  description: string;
  oaType: OAType;
  priority: OAPriority;
  satelliteID: string;
  groundStationID: string;
  missionEntities: string[];
  taskStartTs: ISODateString;
  taskDuration: number;
  procedureExecutionStartTs: ISODateString;
  procedureExecutionDuration: number;
  uuid: string;
  author: string;
  lastModificationAuthor: string;
  creationTs: ISODateString;
  lastModificationTs: ISODateString;
  status: OAStatus;
  fop: {
    type: FOPType;
    templateProcedureID: number;
    resources: number[];
    parameters: any; // {}
    logFileName: string;
    toExecuteProcedureID: number;
    logFileID: number;
    outputFile: string;
    telecommands: {
      onBoardUID: string;
      timeTag: ISODateString;
      boardID: number;
    }[];
  };
}

// The Timeline Data Elements
export interface CalendarTimeline {
  uuid: string;
  name: string;
  timelineType: TimelineTypes;
  groups: CalendarTimelineGroup[];
  items: CalendarTimelineItem[];
}

export interface ITimeline {
  uuid: string;
  name: string;
  timelineType: TimelineTypes;
  entries: TimelineEntry[];
}

export interface TimelineEntry {
  oaUUID: string;
  status: TimelineEntryStatus;
  executionStart: ISODateString;
  executionEnd: ISODateString;
  timelineEntryType: TimelineEntryType;
  timeRanges: [
    {
      startTs: ISODateString;
      endTs: ISODateString;
      priority: number;
    }
  ];
  bookedEntities: string[];
  bookedSatellite: string;
  bookedGroundStation: string;
}

/**
 * Components states
 */
export interface TimelineDataState {
  items: CalendarTimelineItem[];
  groups: CalendarTimelineGroup[];
}

export enum StartTimesTypes {
  passage = "passageStartTime",
  operation = "operationStartTime"
}

export type StartTimes = {
  [key in StartTimesTypes]: string | null;
};

/**
 * Components props
 */

export interface TimelineItemRendererProps {
  item: any;
  itemContext: any;
  getItemProps: any;
  getResizeProps: any;
  selectedCalendarTimeline: CalendarTimeline | null;
  handleItemDelete: (itemId: Id) => void;
}

export type TimelineItemContextMenuProps = any;

export interface NextEventsProps {
  selectedTimeline: CalendarTimeline;
}

export interface DialogContentEditProps {
  operation: Oa;
}
export interface DialogContentDeleteProps {
  operation: Oa;
}
export interface DialogContentInfoProps {
  operation: Oa;
}

export interface PlanningSystemProps {
  getTimeline: (
    params: FetchTimelineParams
  ) => Promise<FetchTimelineResponse | null>;
  getAllPassages: (
    availableSatellites: number[],
    from: Date,
    to: Date
  ) => Promise<Passage[]>;
  getCalendarTimeline: (
    timelines: FetchTimelineResponse
  ) => Promise<CalendarTimeline[]>;
  getAllOperationActivities: (
    params?: FetchAllOperationActivityParams
  ) => Promise<FetchAllOperationActivityResponse>;
}

export interface PlanningSystemTimelineProps {
  availableSatellites: SatelliteInstance[];
  calendarTimeline?: CalendarTimeline[];
  passages: CalendarTimelineItem[];
  dataProvider: (begin: Date, end: Date) => Promise<void>;
}

export interface TimelineCommandsProps {
  availableSatellites: SatelliteInstance[];
  calendarTimeline: CalendarTimeline[];
  handleTimelineSelection: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  handleResetTimelinePosition: () => Promise<void>;
  changeButtonsDisabled: boolean;
  handleUndoTimelineChanges: MouseEventHandler<HTMLButtonElement>;
  timelineItems: CalendarTimelineItem[];
  handleOverlaps: (overlaps: Overlaps) => void;
}

export interface DrawerContentProps {
  operations: Oa[];
  type: string;
}

export interface OaPaginationProps {
  pagination: OaPagination;
  getAllOperationActivities: (
    params?: FetchAllOperationActivityParams
  ) => Promise<FetchAllOperationActivityResponse>;
}

export interface DialogContentHandleProps {
  handleClose: Function;
  satelliteInstances: SatelliteInstance[];
  getProcedures: () => Promise<Procedure[]>;
  getResources: () => Promise<Resource[]>;
  createOperationActivity: (
    params: CreateOaParams
  ) => Promise<CreateOaResponse | CreateOaResponseErrors | null>;
  editOperationActivity: (
    uuid: string,
    params: PatchOaParams
  ) => Promise<EditOaResponse | EditOaResponseErrors | null>;
  getAllOperationActivities: () => Promise<FetchAllOperationActivityResponse>;
  operation?: Oa;
  handleType: HandleType;
}

export interface OperationActivityDialogProps {
  deleteOperationActivity: (
    uuid: string
  ) => Promise<DeleteOaResponse | DeleteOaResponseErrors | null>;
}

export interface TimelinItemTooltipProps {
  coord: { x?: number; y?: number };
  dragTime?: string;
}

/**
 * Services
 */
export type FetchTimelineParams = {
  timelineType?: string;
  page?: number;
  limit?: number;
  startTime: Date;
  endTime: Date;
};

export type FetchTimelineResponse = {
  timelines: ITimeline[];
  metadata: {
    totalRecords: number;
    currentPage: number;
    nextPage: number;
    prevPage: number;
    limit: number;
    currentPageRecords: number;
    totalPages: number;
  };
};

export type FetchAllOperationActivityParams = {
  name?: string;
  fop_type?: string;
  status?: string;
  page?: number;
  page_size?: number;
};

export type OaPagination = {
  currentPage?: number;
  pageSize?: number;
  size?: number;
  total?: number;
  totalPages?: number;
};

export type FetchAllOperationActivityResponse = {
  oas: Oa[];
  pagination: OaPagination;
};

export interface CreateOaParams {
  oa: {
    name: string;
    description: string;
    oaType: OAType;
    priority: OAPriority;
    satelliteID: string;
    groundStationID: string;
    missionEntities: string[];
    taskStartTs: ISODateString;
    taskDuration: number;
    procedureExecutionStartTs: ISODateString;
    procedureExecutionDuration: number;
  };
  fop: {
    type: FOPType;
    templateProcedureID: number;
    parameters: object;
    logFileName: string;
  };
}

export interface PatchOaParams extends Omit<Oa, "fop"> {
  fopResources: number[];
  fopParameters: object;
  fopLogFileName: string;
}

export interface DisapproveParams {
  startTs: ISODateString;
}

export interface CreateOaResponse {
  uuid: string;
  status: ResponseStatus;
  warnings: string[];
}

export type SingleResponseError = { details: string };
export type MultiResponseError = {
  detail: [
    {
      loc: string[] | number[];
      msg: string;
      type: string;
    }
  ];
};

export type TypeOaResponseErrors = SingleResponseError | MultiResponseError;

export type CreateOaResponseErrors = TypeOaResponseErrors;

export type EditOaResponse = string;
export type EditOaResponseErrors = TypeOaResponseErrors;

export type DeleteOaResponse = string;
export type DeleteOaResponseErrors = TypeOaResponseErrors;

export type DisapproveTimelineResponse = string | number;
export type DisapproveTimelineError = SingleResponseError | MultiResponseError;

export type ApproveTimelineResponse = string | number;
export type ApproveTimelineError = SingleResponseError | MultiResponseError;

export type UpdateTimelineResponse = string | number;
export type UpdateTimelineError = SingleResponseError | MultiResponseError;

/**
 * Redux
 */
export interface PlanningSyatemStoreState {
  oa: Oa[];
  oaPagination: OaPagination | null;
  timelines: FetchTimelineResponse | null;
  calendarTimeline: CalendarTimeline[];
}

export type planningSystemActions =
  | GetAllOperationActivitiesAction
  | GetTimelineAction
  | GetCalendarTimelineAction
  | CreateOaAction;

export enum PlanningSystemActionType {
  GetOperationActivities = "GET_OA",
  GetTimeline = "GET_TIMELINE",
  GetCalendarTimeline = "GET_CALENDAR_TIMELINE",
  CreateOa = "CREATE_OA",
  EditOa = "EDIT_OA",
  DisapproveTimeline = "DISAPPROVE_TIMELINE",
  ApproveTimeline = "APPROVE_TIMELINE",
  UpdateProposedTimeline = "UPDATE_PROPOSED_TIMELINE"
}

export interface GetAllOperationActivitiesAction {
  type: PlanningSystemActionType.GetOperationActivities;
  payload: FetchAllOperationActivityResponse | null;
}

export interface GetTimelineAction {
  type: PlanningSystemActionType.GetTimeline;
  payload: FetchTimelineResponse | null;
}
export interface GetCalendarTimelineAction {
  type: PlanningSystemActionType.GetCalendarTimeline;
  payload: CalendarTimeline[];
}
export interface CreateOaAction {
  type: PlanningSystemActionType.CreateOa;
  payload: CreateOaResponse | CreateOaResponseErrors;
}
export interface EditOaAction {
  type: PlanningSystemActionType.EditOa;
  payload: EditOaResponse | EditOaResponseErrors;
}
export interface DisapproveTimelineAction {
  type: PlanningSystemActionType.DisapproveTimeline;
  payload: DisapproveTimelineResponse | DisapproveTimelineError;
}
export interface ApproveTimelineAction {
  type: PlanningSystemActionType.ApproveTimeline;
  payload: ApproveTimelineResponse | ApproveTimelineError;
}
export interface UpdateProposedTimelineAction {
  type: PlanningSystemActionType.UpdateProposedTimeline;
  payload: UpdateTimelineResponse | UpdateTimelineError;
}

export type GetTimelineActionResult = ThunkAction<
  Promise<FetchTimelineResponse | null>,
  {},
  {},
  GetTimelineAction
>;

export type GetCalendarTimelineActionResult = ThunkAction<
  Promise<CalendarTimeline[]>,
  {},
  {},
  GetCalendarTimelineAction
>;

export type GetAllOperationActivitiesResult = ThunkAction<
  Promise<FetchAllOperationActivityResponse>,
  {},
  {},
  GetAllOperationActivitiesAction
>;

export type CreateOaResult = ThunkAction<
  Promise<CreateOaResponse | CreateOaResponseErrors | null>,
  {},
  {},
  CreateOaAction
>;

export type EditOaResult = ThunkAction<
  Promise<EditOaResponse | EditOaResponseErrors | null>,
  {},
  {},
  EditOaAction
>;

export type DisapproveTimelineResult = ThunkAction<
  Promise<DisapproveTimelineResponse | DisapproveTimelineError | null>,
  {},
  {},
  DisapproveTimelineAction
>;

export type ApproveTimelineResult = ThunkAction<
  Promise<ApproveTimelineResponse | ApproveTimelineError | null>,
  {},
  {},
  ApproveTimelineAction
>;

export type UpdateProposedTimelineResult = ThunkAction<
  Promise<UpdateTimelineResponse | UpdateTimelineError | null>,
  {},
  {},
  UpdateProposedTimelineAction
>;

/**
 * Context
 */
export interface IContext {
  navigationDrawerVisible: boolean;
  operationActivityDialog: OperationActivityDialogState | null;
  contextMenu: React.ReactNode | null;
  timelineItemDialog: TimelineItemDialogState | null;
  timelineDialog: TimelineDialogState | null;
  selectedTimeline: CalendarTimeline | null;
  selectedSatellites: string[];
  timelineWindow: TimelineWindow;
}

export type IContextActions = {
  type?: ContextActionsNames;
  payload: Partial<IContext> | IContext[keyof IContext];
};

export enum ContextActionsNames {
  operationActivityDialog = "operationActivityDialog",
  navigationDrawerVisible = "navigationDrawerVisible",
  contextMenu = "contextMenu",
  timelineItemDialog = "timelineItemDialog",
  selectedTimeline = "selectedTimeline",
  selectedSatellites = "selectedSatellites",
  timelineWindow = "timelineWindow",
  timelineDialog = "timelineDialog"
}

export interface OperationActivityDialogState {
  oa?: Oa;
  isOpen: boolean;
  setOpen: Dispatch<IContextActions>;
  actionName: OperationDialogActionName;
}

export interface TimelineDialogState {
  isOpen: boolean;
}

export interface TimelineItemDialogState {
  item: CalendarTimelineItem;
  isOpen: boolean;
  setOpen: Dispatch<IContextActions>;
}

export interface OaHandleState
  extends Omit<
    Oa,
    | "name"
    | "uuid"
    | "author"
    | "lastModificationAuthor"
    | "creationTs"
    | "lastModificationTs"
    | "status"
    | "fop"
    | "taskStartTs"
    | "missionEntities"
    | "taskDuration"
    | "procedureExecutionStartTs"
    | "procedureExecutionDuration"
  > {
  name: string | undefined;
  fopType: FOPType;
  missionEntities: { entity: string }[];
  taskSetting: {
    startTime: Date | string | undefined;
    durationTime: number | undefined;
  };
  procedureExecutionSetting: {
    startTime: Date | string | undefined;
    durationTime: number | undefined;
  };
  fop: {
    templateProcedureID: number;
    parameters?: string;
    logFileName: string;
  };
}

export interface SchemaErrors {
  [key: string]: any;
}

/**
 * Generic types
 */
export type CalendarTimelineItem = TimelineItemBase<number>;
export type CalendarTimelineGroup = TimelineGrp<any>;

export enum OperationDialogActionName {
  edit = "edit",
  delete = "delete",
  info = "info",
  create = "create"
}

export enum TimelineTypes {
  MASTER = "MASTER",
  PROPOSED = "PROPOSED",
  REJECTED = "REJECTED"
}

export enum TimelineEntryStatus {
  REJECTED = "REJECTED",
  TASKED = "TASKED",
  IN_EXECUTION = "IN_EXECUTION",
  CANCELLED = "CANCELLED",
  SUCCESS = "SUCCESS",
  CONFLICT = "CONFLICT",
  PROPOSED = "PROPOSED",
  OBCP_ACTIVATION_FAILED = "OBCP_ACTIVATION_FAILED",
  OBCP_WRONG_ARGUMENT = "OBCP_WRONG_ARGUMENT",
  TT_EXPIRED = "TT_EXPIRED",
  GENERIC_FAILURE = "GENERIC_FAILURE"
}

export enum OAType {
  NOMINAL = "NOMINAL",
  CONTINGENT = "CONTINGENT"
}

export enum OAPriority {
  OPS_TEAM_OVERRIDE = "OPS_TEAM_OVERRIDE",
  CUST_ROUTINE_PLAN = "CUST_ROUTINE_PLAN",
  OPS_ROUTINE_PLAN = "OPS_ROUTINE_PLAN"
}

export enum OAStatus {
  DRAFT = "DRAFT",
  SUBMITTED = "SUBMITTED",
  REJECTED = "REJECTED",
  ACCEPTED = "ACCEPTED",
  TASKED = "TASKED",
  IN_EXECUTION = "IN_EXECUTION",
  CANCELLED = "CANCELLED",
  SUCCESS = "SUCCESS",
  FAILED = "FAILED"
}

// Flight Operation Procedure Type
export enum FOPType {
  GOP = "GOP",
  MOP = "MOP"
}

export enum ResponseStatus {
  OK = "OK",
  CREATED_WITH_WARNINGS = "CREATED_WITH_WARNINGS"
}

export enum TimelineEntryType {
  EXECUTE = "EXECUTE",
  TRACK = "TRACK"
}

export enum TimelineItemResizeSide {
  left = "left",
  right = "right",
  both = "both"
}

export interface OperationActivityFilterState {
  name: string | null;
  oaType: string | null;
  status: string | null;
}

export type SatelliteFilter = {
  id: string;
  name: string;
};

export type TimeRange = {
  start_time: number;
  end_time: number;
};

export type ISODateString = `${string}T${string}.${string}Z`;

export type TimelineWindow = {
  startTime: Date;
  endTime: Date;
};

export type HandleType =
  | OperationDialogActionName.create
  | OperationDialogActionName.edit;

export type Overlaps = {
  track: CalendarTimelineItem;
  relatedExecute: CalendarTimelineItem;
} | null;
