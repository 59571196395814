import React from "react";
import { Switch, Route } from "react-router-dom";
import { PassageManager } from "./components/Booker";
import { Configs } from "./components/Config";
import { BookingConfigExport } from "./components/Config/ImportExport/Export";
import { BookingConfigImport } from "./components/Config/ImportExport/Import";
import { ConfigCreate } from "./components/Config/ConfigForm/ConfigCreate";

export const PassageManagerRoutes = () => (
  <>
    <Switch>
      <Route path="/passage-manager" exact component={Configs} />
      <Route path="/passage-manager/create" exact component={ConfigCreate} />
      <Route path="/passage-manager-configs" exact component={Configs} />
      <Route
        path="/passage-manager-candidates"
        exact
        component={PassageManager}
      />
      <Route
        path="/passage-manager/import"
        exact
        component={BookingConfigImport}
      />
      <Route
        path="/passage-manager/export"
        exact
        component={BookingConfigExport}
      />
    </Switch>
  </>
);
