import { Box, Flex, Text } from "primitives";
import React from "react";
import styled from "styled-components";
import { Switch } from "components";
import { DataSourceIdLabel } from "./SelectDataSources";

const DataSourceBox = styled(Box)<{ checked: boolean }>`
  box-sizing: border-box;
  background-color: ${(props) => (props.checked ? "#34435a" : "#1F1E2C")};
  padding: 8px;
  border: 0.5px solid #27293a;
  overflow: visible !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #7ab9db;
  margin: 10px;
  height: 40px;
  font-weight: 600;
  font-size: 15px;
`;

const TitleBox = styled(Flex)`
  overflow: visible !important;
  font-size: 17px;
  margin: 10px;
  color: white;
  justify-content: space-between;
`;

interface DataSourcesMenuProps {
  setDataSources: (ds: any) => void;
  setInitialSelectedDS: (ds: any) => void;
  dataSources: (number | DataSourceIdLabel)[];
  initialSelectedDS: DataSourceIdLabel[];
}

export const DataSourcesMenu = ({
  setDataSources,
  dataSources,
  initialSelectedDS,
  setInitialSelectedDS
}: DataSourcesMenuProps) => {
  return (
    <Flex id="selectedDataSources" flexDirection="column" width="35%" mr={14}>
      <TitleBox>
        <Text>Selected Datasources</Text>
        {Boolean(initialSelectedDS.length) && (
          <Text
            id="RemoveAll"
            cursor="pointer"
            onClick={() => {
              setInitialSelectedDS([]);
              return setDataSources([]);
            }}
          >
            Remove All
          </Text>
        )}
      </TitleBox>
      {Array.isArray(dataSources) &&
        initialSelectedDS.map((ds: any) => {
          const checked = dataSources.includes(ds.id);
          return (
            <DataSourceBox checked={checked}>
              {ds.name || ds.title}
              <Switch
                checked={checked}
                onChange={(event) => {
                  if (event.currentTarget.checked) {
                    setDataSources((d: number[]) => [...d, ds.id]);
                  } else {
                    setDataSources((d: number[]) =>
                      d.filter((i) => i !== ds.id)
                    );
                  }
                }}
              ></Switch>
            </DataSourceBox>
          );
        })}
    </Flex>
  );
};
