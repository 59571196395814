export interface GroundStation {
  coordinates: {
    elevation: number;
    latitude: number;
    longitude: number;
  };
  groundStationID: number;
  groundStationName: string;
  providerID: number;
  status: boolean;
  type: GroundStationType.MQTT;
}

enum GroundStationType {
  MQTT = "MQTT",
  VPN = "VPN",
  API = "API"
}

export interface GroundStationConfiguration {
  providerID?: number;
  providerName?: string;
  satelliteID: number;
  groundStationID: number;
  groundStationName?: string;
  type: GroundStationType.MQTT | GroundStationType.VPN | GroundStationType.API;
  configuration: {
    IP: string;
    port: number;
    topic: string;
    username: string;
    password: string;
    sslEnabled: boolean;
    ssl?: any;
  };
}

export interface Provider {
  providerId?: number;
  providerName?: string;
  status?: string;
}
