import { GroundStation, GroundStationConfiguration, Provider } from "../models";
import { Dispatch } from "react";
import { ThunkAction } from "redux-thunk";
import {
  fetchGroundStations,
  fetchGroundStation,
  fetchGroundStationConfigList,
  fetchGroundStationConfig,
  fetchProviders
} from "../services";

export enum GroundStationActionType {
  FetchGroundStation = "FETCH_GROUNDSTATION",
  FetchGroundStations = "FETCH_GROUNDSTATIONS",
  FetchProviders = "FETCH_PROVIDERS",
  FetchGroundStationConfigs = "FETCH_GROUNDSTATION_CONFIGS"
}

export enum GroundStationConfigActionType {
  FetchGroundStationConfig = "FETCH_GROUNDSTATION_CONFIG",
  FetchGroundStationConfigList = "FETCH_GROUNDSTATION_CONFIG_LIST"
}

export interface FetchGroundStationsAction {
  type: GroundStationActionType.FetchGroundStations;
  payload: GroundStation[];
}

export interface FetchGroundStationAction {
  type: GroundStationActionType.FetchGroundStation;
  payload: GroundStation | null;
}

export interface FetchProvidersAction {
  type: GroundStationActionType.FetchProviders;
  payload: Provider[];
}

export interface FetchGroundStationConfigsAction {
  type: GroundStationActionType.FetchGroundStationConfigs;
  payload: GroundStationConfiguration[];
}

export interface FetchGroundStationConfigListAction {
  type: GroundStationConfigActionType.FetchGroundStationConfigList;
  payload: GroundStationConfiguration[];
}

export interface FetchGroundStationConfigAction {
  type: GroundStationConfigActionType.FetchGroundStationConfig;
  payload: GroundStationConfiguration | null;
}

export type GroundStationActions =
  | FetchGroundStationsAction
  | FetchGroundStationAction
  | FetchGroundStationConfigsAction
  | FetchProvidersAction;

export type GroundStationConfigActions =
  | FetchGroundStationConfigListAction
  | FetchGroundStationConfigAction;

export type FetchGroundStationActionResult = ThunkAction<
  Promise<GroundStation | null>,
  {},
  {},
  FetchGroundStationAction
>;
export const fetchGroundStationAction = (
  id: number
): FetchGroundStationActionResult => {
  return async (dispatch: Dispatch<FetchGroundStationAction>) => {
    try {
      const payload = await fetchGroundStation(id);
      dispatch({
        type: GroundStationActionType.FetchGroundStation,
        payload
      });
      return Promise.resolve(payload);
    } catch (e) {
      dispatch({
        type: GroundStationActionType.FetchGroundStation,
        payload: null
      });
      return Promise.resolve(null);
    }
  };
};

export type FetchGroundStationsActionResult = ThunkAction<
  Promise<GroundStation[]>,
  {},
  {},
  FetchGroundStationsAction
>;
export const fetchGroundStationsAction =
  (): FetchGroundStationsActionResult => {
    return async (dispatch: Dispatch<FetchGroundStationsAction>) => {
      try {
        const payload = await fetchGroundStations();
        dispatch({
          type: GroundStationActionType.FetchGroundStations,
          payload
        });
        return Promise.resolve(payload);
      } catch (e) {
        return Promise.resolve([]);
      }
    };
  };

export type FetchGroundStationConfigListActionResult = ThunkAction<
  Promise<GroundStationConfiguration[]>,
  {},
  {},
  FetchGroundStationConfigListAction
>;
export const fetchGroundStationConfigListAction =
  (): FetchGroundStationConfigListActionResult => {
    return async (
      dispatch: Dispatch<
        FetchGroundStationConfigsAction | FetchGroundStationConfigListAction
      >
    ) => {
      try {
        const payload = await fetchGroundStationConfigList();
        dispatch({
          type: GroundStationConfigActionType.FetchGroundStationConfigList,
          payload
        });
        dispatch({
          type: GroundStationActionType.FetchGroundStationConfigs,
          payload
        });
        return Promise.resolve(payload);
      } catch (e) {
        return Promise.resolve([]);
      }
    };
  };

export type FetchGroundStationConfigActionResult = ThunkAction<
  Promise<GroundStationConfiguration | null>,
  {},
  {},
  FetchGroundStationConfigAction
>;
export const fetchGroundStationConfigAction = (
  satelliteID: number,
  groundStationID: number
): FetchGroundStationConfigActionResult => {
  return async (dispatch: Dispatch<FetchGroundStationConfigAction>) => {
    try {
      const payload = await fetchGroundStationConfig(
        satelliteID,
        groundStationID
      );
      dispatch({
        type: GroundStationConfigActionType.FetchGroundStationConfig,
        payload
      });
      return Promise.resolve(payload);
    } catch (e) {
      dispatch({
        type: GroundStationConfigActionType.FetchGroundStationConfig,
        payload: null
      });
      return Promise.resolve(null);
    }
  };
};

export const fetchProvidersAction = () => {
  return async (dispatch: Dispatch<FetchProvidersAction>) => {
    try {
      const payload = await fetchProviders();
      dispatch({
        type: GroundStationActionType.FetchProviders,
        payload
      });
      return Promise.resolve(payload);
    } catch (e) {
      return Promise.resolve([]);
    }
  };
};
