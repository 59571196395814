import { SelectBox, InputBase } from "app/shared";
import { groundStations } from "app/shared/testUtils";
import { DateTimePicker } from "components";
import { Providers } from "../../../models";
import { QuickRange } from "app/passageManager/utils";

export const bookerConfigSchemaCreate = (
  type: string,
  bookerConfigs: any[] = [],
  configs?: any
) => {
  const isBooker = type === "booker";
  const configSelector = (bookerConfigs?: any[]) => {
    if (bookerConfigs && !Array.isArray(bookerConfigs)) {
      return {};
    }
    return {
      configuration: {
        type: "number",
        title: "Select a passage manager configuration",
        enum: bookerConfigs?.map((p) => p.id),
        enumNames: bookerConfigs?.map((p) => p.name)
      }
    };
  };
  return {
    $schema: "http://json-schema.org/draft-07/schema#",
    $id: "passages.schema.json",
    title: "Fetch Passages based on following configuration",
    name: "Fetch Passages",
    description:
      "The configuration options to book passages from various providers",
    type: "object",
    definitions: {
      time: {
        $id: "#/definitions/time",
        title: "Time",
        type: "string"
      }
    },
    properties: {
      ...(isBooker ? configSelector(bookerConfigs) : {}),
      name: {
        type: "string",
        title: "Name for the passage manager configuration",
        default: ""
      },
      description: {
        type: "string",
        title: "description",
        default: ""
      },
      groundStationsBySatellite: {
        type: "array",
        hasRemove: true,
        title: "Select GroundStations for each satellite",
        items: {
          type: "object",
          properties: {
            satellite: {
              title: "Satellite",
              description:
                "Select a satellite to list configured groundstations",
              type: "string",
              enum: configs ? Object.keys(configs).sort() : []
            }
          },
          dependencies: {
            satellite: {
              oneOf: Object.keys(configs).map((satellite) => ({
                properties: {
                  satellite: {
                    enum: [satellite]
                  },
                  groundStations: {
                    type: "array",
                    title: "Ground Stations",
                    description:
                      "Ground Stations configured for selected satellite",
                    items: {
                      type: "string",
                      enum: configs[satellite]?.map(
                        (gs: { groundStationName: string }) => {
                          return gs.groundStationName;
                        }
                      )
                    },
                    uniqueItems: true
                  }
                }
              }))
            }
          }
        }
      },
      additionalData: {
        title: "Add additional config options",
        type: "object",
        properties: {
          config: {
            type: "string",
            title: " "
          }
        }
      }
    }
  };
};

export const bookerConfigSchemaEdit = (
  type: string,
  bookerConfigs?: any[],
  configs?: any
) => {
  return {
    type: "object",
    properties: {
      id: {
        type: "number",
        title: "Id",
        kind: "constant"
      },
      ...bookerConfigSchemaCreate(type, bookerConfigs, configs).properties
    }
  };
};

export const bookerConfigDefault = {};

export const bookerConfigUISchema = {
  configuration: { "ui:widget": SelectBox },
  name: { "ui:widget": InputBase },
  description: { "ui:widget": InputBase },
  quickrange: { "ui:widget": SelectBox },
  daterange: {
    classNames: "daterange-passage-manager",
    from: {
      "ui:widget": DateTimePicker,
      classNames: "daterange-passage-manager-from"
    },
    to: {
      "ui:widget": DateTimePicker,
      classNames: "daterange-passage-manager-to"
    }
  },
  groundStationsBySatellite: {
    items: {
      satellite: { classNames: "multi-select-sats", "ui:widget": SelectBox },
      groundStations: {
        "ui:widget": "checkboxes",
        classNames: "multi-select-gs"
      }
    }
  },
  additionalData: {
    config: {
      "ui:widget": InputBase
    }
  }
};

export const bookerConfigUISchemEdit = {
  id: { "ui:widget": "hidden" },
  ...bookerConfigUISchema
};
