import React, { useEffect, useCallback, useState } from "react";
import { SuspenseMutation } from "app/network";
import { Toggler } from "components";
import { CancelButton, EditButton, SaveButton } from "components";
import {
  Flex,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent
} from "primitives";
import { useSelector, useDispatch } from "react-redux";
import { Form } from "app/shared";
import { processFormData, transformFormData } from "app/passageManager/utils";
import { updateBookerConfig as update } from "app/passageManager/actions";
import {
  bookerConfigSchemaEdit,
  bookerConfigUISchemEdit
} from "../ConfigForm/configSchema";
import { PassageManagerConfigEditProps } from "app/passageManager/models";

export const ConfigEdit = ({ config }: PassageManagerConfigEditProps) => {
  const dispatch = useDispatch();
  const satellites = useSelector((s: any) => s.constellations.dashboard);
  const { groundStationConfigs } = useSelector((s: any) => s.passageManager);

  const submit = async (
    e: React.FormEvent<HTMLInputElement>,
    formData: any,
    onToggle: any
  ) => {
    e.preventDefault();
    const formattedData = transformFormData(
      formData,
      satellites,
      groundStationConfigs
    );
    const notUpdated = await dispatch(
      update(config.id, { id: config.id, ...formattedData })
    );
    !notUpdated && onToggle();
  };

  const transformData = (formData: any): any => {
    return formData;
  };
  const onChange = useCallback((form) => {
    transformData(form.formData);
  }, []);

  return (
    <Toggler>
      {([toggled, onToggle]) => (
        <>
          <EditButton onClick={onToggle}>EDIT</EditButton>
          <Dialog
            open={toggled}
            maxWidth="lg"
            data-testid="passage-manager-edit"
          >
            <SuspenseMutation>
              {({ loading }) => {
                return (
                  <>
                    <DialogTitle>
                      Edit passage booking configuration with id: {config.id}?
                    </DialogTitle>
                    <DialogContent>
                      <Flex alignItems="center">
                        <Form
                          noValidate
                          id="passage-manager"
                          formData={processFormData(config, groundStationConfigs)}
                          schema={bookerConfigSchemaEdit(
                            "config",
                            [],
                            groundStationConfigs
                          )}
                          uiSchema={bookerConfigUISchemEdit}
                          disabled={loading}
                          onSubmit={(
                            form: { formData: any },
                            e: React.FormEvent<HTMLInputElement>
                          ) => {
                            return submit(e, form.formData, onToggle);
                          }}
                          onChange={(form: any) => onChange(form)}
                        >
                          <></>
                        </Form>
                      </Flex>
                    </DialogContent>
                    <DialogActions>
                      <SaveButton form="passage-manager" type="submit" mr={1}>
                        Save
                      </SaveButton>
                      <CancelButton onClick={onToggle} disabled={loading}>
                        Cancel
                      </CancelButton>
                    </DialogActions>
                  </>
                );
              }}
            </SuspenseMutation>
          </Dialog>
        </>
      )}
    </Toggler>
  );
};
