import React from "react";
import { InputBase, SelectBox } from "app/shared";
import {
  OAType,
  OAPriority,
  FOPType,
  HandleType,
  OperationDialogActionName
} from "app/planningSystem/models";
import { SatelliteInstance } from "app/satellite/models";
import { GroundStation } from "app/groundStation";
import { DateTimePicker, EmbeddedEditorEditor } from "components";
import { Box } from "primitives";
import { Procedure } from "app/procedure/models";
import { isValidDate } from "app/planningSystem/utils/helpers";

const generateOaHandleSchema = (
  satelliteInstances: SatelliteInstance[],
  gs: GroundStation[],
  procedure: Procedure[],
  handleType: HandleType
) => {
  return {
    $schema: "http://json-schema.org/draft-07/schema#",
    $id: "operation.activity.create.schema.json",
    type: "object",
    definitions: {
      name: {
        $id: "#/definitions/name",
        title: "Name",
        type: "string"
      },
      description: {
        $id: "#/definitions/description",
        title: "Description",
        type: "string"
      },
      oaType: {
        title: "Operation Activity Type",
        $id: "#/definitions/oaType",
        type: "string",
        enum: Object.values(OAType)
      },
      priority: {
        title: "Operation Activity Priority",
        $id: "#/definitions/priority",
        type: "string",
        enum: Object.values(OAPriority)
      },
      satelliteID: {
        title: "Satellite",
        $id: "#/definitions/satelliteID",
        type: "string",
        enum: satelliteInstances.map((sat: SatelliteInstance) =>
          String(sat.id)
        ),
        enumNames: satelliteInstances.map((sat: SatelliteInstance) => sat.label)
      },
      groundStationID: {
        title: "Groundstation",
        $id: "#/definitions/groundStationID",
        type: "string",
        enum: (gs.length > 0 &&
          gs.map((g: GroundStation) => String(g.groundStationID))) || ["0"],
        enumNames: (gs.length > 0 &&
          gs.map((g: GroundStation) => g.groundStationName)) || [
          "Select a satellite"
        ],
        readOnly: gs.length === 0
      },
      missionEntities: {
        title: "Mission Entities",
        $id: "#/definitions/missionEntities",
        type: "array",
        hasRemove: true,
        items: {
          type: "object",
          properties: {
            entity: {
              type: "string",
              title: "Entity name",
              default: null
            }
          }
        }
      },
      startTime: {
        type: "string",
        format: "date-time",
        $id: "#/definitions/startTime"
      },
      durationTime: {
        type: "number",
        $id: "#/definitions/durationTime"
      },
      fopType: {
        $id: "#/definitions/fopType",
        type: "string",
        enum: [FOPType.GOP, FOPType.MOP]
      },
      templateProcedureID: {
        $id: "#/definitions/templateProcedureID",
        type: "number",
        enum: procedure.map(({ id }) => id),
        enumNames: procedure.map(({ name }) => name)
      },
      parameters: {
        $id: "#/definitions/parameters",
        type: "string"
      },
      logFileName: {
        $id: "#/definitions/logFileName",
        type: "string"
      }
    },
    properties: {
      name: {
        $ref: "#/definitions/name"
      },
      description: {
        $ref: "#/definitions/description"
      },
      oaType: {
        $ref: "#/definitions/oaType"
      },
      priority: {
        $ref: "#/definitions/priority"
      },
      taskSetting: {
        title: "Task settings",
        type: "object",
        properties: {
          startTime: {
            $id: "#/properties/taskSetting/properties/startTime",
            title: "Task start time",
            $ref: "#/definitions/startTime"
          },
          durationTime: {
            $id: "#/properties/taskSetting/properties/durationTime",
            title: "Duration in seconds",
            $ref: "#/definitions/durationTime"
          }
        },
        required: ["durationTime", "startTime"]
      },
      fop: {
        title: "Flight Operation Procedure",
        type: "object",
        properties: {
          templateProcedureID: {
            $id: "#/properties/fop/properties/templateProcedureID",
            title: "Template Procedure ID",
            $ref: "#/definitions/templateProcedureID",
            readOnly: handleType === OperationDialogActionName.edit,
            searchableList: handleType !== OperationDialogActionName.edit
          },
          parameters: {
            $id: "#/properties/fop/properties/parameters",
            title: "Parameters",
            $ref: "#/definitions/parameters"
          },
          logFileName: {
            $id: "#/properties/fop/properties/logFileName",
            title: "Log file name",
            $ref: "#/definitions/logFileName"
          }
        },
        required: ["templateProcedureID", "logFileName"]
      },
      fopType: {
        $id: "#/properties/fop/properties/fopType",
        title: "FOP type",
        $ref: "#/definitions/fopType",
        readOnly: handleType === OperationDialogActionName.edit
      }
    },
    required: ["name", "description", "oaType", "priority", "fopType"],
    dependencies: {
      fopType: {
        oneOf: [
          { properties: { fopType: { enum: [FOPType.GOP] } } },
          {
            properties: {
              fopType: { enum: [FOPType.MOP] },
              satelliteID: {
                $ref: "#/definitions/satelliteID"
              },
              groundStationID: {
                $ref: "#/definitions/groundStationID"
              },
              missionEntities: {
                $ref: "#/definitions/missionEntities"
              },
              procedureExecutionSetting: {
                title: "Procedure Execution Settings",
                type: "object",
                properties: {
                  startTime: {
                    $id: "#/properties/procedureExecutionSettings/properties/startTime",
                    title: "Task start time",
                    $ref: "#/definitions/startTime"
                  },
                  durationTime: {
                    $id: "#/properties/procedureExecutionSettings/properties/durationTime",
                    title: "Duration in seconds",
                    $ref: "#/definitions/durationTime"
                  }
                },
                required: ["durationTime", "startTime"]
              }
            },
            required: [
              "satelliteID",
              "groundStationID",
              "missionEntities",
              "procedureExecutionSetting"
            ]
          }
        ]
      }
    }
  };
};

const operationActivityHendleDefault = {};

const operationActivityHandleUISchema = {
  name: { "ui:widget": InputBase },
  description: { "ui:widget": InputBase },
  oaType: { "ui:widget": SelectBox },
  priority: { "ui:widget": SelectBox },
  fopType: { "ui:widget": SelectBox },
  satelliteID: { "ui:widget": SelectBox },
  groundStationID: { "ui:widget": SelectBox },
  missionEntities: {
    items: {
      entity: { "ui:widget": InputBase }
    }
  },
  procedureExecutionSetting: {
    classNames: "daterange-planning-system",
    startTime: {
      "ui:widget": (props: any) => {
        return (
          <DateTimePicker
            {...props}
            value={props.value && new Date(props.value)}
            isValidDate={isValidDate()}
          />
        );
      }
    },
    durationTime: { "ui:widget": InputBase }
  },
  taskSetting: {
    classNames: "daterange-planning-system",
    startTime: {
      "ui:widget": (props: any) => {
        return (
          <DateTimePicker
            {...props}
            value={props.value && new Date(props.value)}
            isValidDate={isValidDate()}
          />
        );
      }
    },
    durationTime: { "ui:widget": InputBase }
  },
  fop: {
    templateProcedureID: {
      "ui:widget": (props: any) => (
        <Box mt={1} mb={3}>
          <SelectBox {...props} />
        </Box>
      )
    },
    parameters: {
      "ui:widget": (props: any) => (
        <EmbeddedEditorEditor {...props} mode={"yaml"} />
      )
    },
    logFileName: { "ui:widget": "hidden" }
  }
};

const timelineDisapproveSchema = {
  $schema: "http://json-schema.org/draft-07/schema#",
  $id: "operation.activity.create.schema.json",
  type: "object",
  definitions: {
    startTime: {
      type: "string",
      $id: "#/definitions/startTime"
    }
  },
  properties: {
    startTime: {
      description: "All timeline entries above this date will be disapproved",
      title: "Disapprove from",
      $ref: "#/definitions/startTime"
    }
  },
  required: ["startTime"]
};
const timelineDisapproveDefault = {};
const timelineDisapproveUISchema = {
  classNames: "daterange-planning-system",
  startTime: {
    "ui:widget": (props: any) => (
      <DateTimePicker
        {...props}
        value={props.value ? new Date(props.value) : props.value}
        isValidDate={isValidDate()}
      />
    )
  }
};

export {
  generateOaHandleSchema,
  operationActivityHendleDefault,
  operationActivityHandleUISchema,
  timelineDisapproveSchema,
  timelineDisapproveDefault,
  timelineDisapproveUISchema
};
