import { GroundStation, GroundStationConfiguration, Provider } from "../models";
import {
  GroundStationActions,
  GroundStationActionType,
  GroundStationConfigActionType
} from "../actions";

interface GroundStationStoreState {
  groundStations: GroundStation[];
  providers?: Provider[];
  groundStationConfigs?: GroundStationConfiguration[];
  selectedGroundStation?: GroundStation | null;
}

const initialState: GroundStationStoreState = {
  groundStations: [],
  providers: [],
  groundStationConfigs: [],
  selectedGroundStation: null
};

export const groundStationReducer = (
  state: GroundStationStoreState = initialState,
  action: GroundStationActions
): GroundStationStoreState => {
  switch (action.type) {
    case GroundStationActionType.FetchGroundStations:
      return { ...state, groundStations: action.payload };

    case GroundStationActionType.FetchProviders:
      return { ...state, providers: action.payload };

    case GroundStationActionType.FetchGroundStationConfigs:
      return { ...state, groundStationConfigs: action.payload };

    case GroundStationActionType.FetchGroundStation:
      return {
        ...state,
        selectedGroundStation: action.payload
      };

    default:
      return state;
  }
};
