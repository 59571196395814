import React, { useContext, useMemo } from "react";
import {
  Flex,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Icon,
  Heading,
  Button,
  Text
} from "primitives";
import { SuspenseMutation } from "app/network";
import {
  CancelButton,
  Table,
  TableBody,
  TableRow,
  TableCell
} from "components";
import { PlanningSystemContext } from "app/planningSystem/context";
import {
  ContextActionsNames,
  OperationDialogActionName,
  PlanningSyatemStoreState,
  ITimeline
} from "app/planningSystem/models";
import moment from "moment";
import { dateTimeFormat } from "app/planningSystem/utils/constants";
import { stripTypeFromOauuid } from "app/planningSystem/utils/helpers";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { store } from "app/store";
import { FeedbackStatus } from "app/feedback/models";
import { setFeedback } from "app/feedback/actions";

const Td = styled(TableCell)`
  text-wrap: wrap;
`;

export const TimelineItemDialog = () => {
  const { oa: operationActivity } = useSelector<any, any>(
    (s) => s.planningSystem
  ) as PlanningSyatemStoreState;

  const {
    state: { timelineItemDialog, selectedTimeline: selectedCalendarTimeline },
    dispatch
  } = useContext(PlanningSystemContext);
  const { isOpen, item } = timelineItemDialog || {
    isOpen: false
  };

  const selectedTimeline: ITimeline = useSelector((s: any) => {
    return s.planningSystem.timelines?.timelines.find(
      (t: ITimeline) => t.uuid === selectedCalendarTimeline?.uuid
    );
  });

  /**
   * Getting the `TimelineEntry` from the ITimelineEntry by id
   */
  const entry = useMemo(() => {
    return (
      selectedTimeline &&
      selectedTimeline.entries.find(
        (e) => `${e.oaUUID}-${e.timelineEntryType}` === item?.id
      )
    );
  }, [item?.id, selectedTimeline]);

  const handleShowOperationActivity = () => {
    const oa = operationActivity.find(
      (o) => o.uuid === stripTypeFromOauuid(String(timelineItemDialog?.item.id))
    );

    if (!oa) {
      return store.dispatch(
        setFeedback(
          "Warning:",
          FeedbackStatus.WARNING,
          "The Operation Activity for this Timeline Item was not found"
        )
      );
    }

    const payload = {
      actionName: OperationDialogActionName.edit,
      isOpen: true,
      oa,
      setOpen: dispatch
    };

    // Open the OperationActivityDialog
    dispatch({ type: ContextActionsNames.operationActivityDialog, payload });

    // Close the TimelineItemDialog
    dispatch({
      type: ContextActionsNames.timelineItemDialog,
      payload: null
    });
  };

  return (
    <Dialog open={isOpen} maxWidth="sm" data-testid={`timeline-item-dialog`}>
      {item && (
        <SuspenseMutation>
          {({ loading, action }) => {
            return (
              <>
                <DialogTitle>
                  <Flex alignItems={"center"}>
                    <Icon name={"Info"} size={20} />
                    <Heading display={1} ml={2}>
                      Timeline Entry Info
                    </Heading>
                  </Flex>
                </DialogTitle>
                <DialogContent>
                  <Flex justifyContent={"flex-end"} mb={3}>
                    <Button
                      size={"small"}
                      onClick={handleShowOperationActivity}
                    >
                      <Text mr={2}>Show operation activity</Text>
                      <Icon name="ArrowRight" size={15} />
                    </Button>
                  </Flex>
                  <Table>
                    <TableBody>
                      <TableRow>
                        <Td>id</Td>
                        <Td>{stripTypeFromOauuid(String(item.id))}</Td>
                      </TableRow>
                      <TableRow>
                        <Td>Start time</Td>
                        <Td>
                          {`${moment(item.start_time).format(
                            dateTimeFormat
                          )} UTC`}
                        </Td>
                      </TableRow>
                      <TableRow>
                        <Td>End time</Td>
                        <Td>
                          {`${moment(item.end_time).format(
                            dateTimeFormat
                          )} UTC`}
                        </Td>
                      </TableRow>
                      {entry && (
                        <>
                          <TableRow>
                            <Td>Status</Td>
                            <Td>{entry?.status}</Td>
                          </TableRow>
                          {entry?.bookedEntities && (
                            <TableRow>
                              <Td>Booked entities</Td>
                              <Td>{entry?.bookedEntities[0]}</Td>
                            </TableRow>
                          )}
                          {entry?.bookedSatellite && (
                            <TableRow>
                              <Td>Booked satellite</Td>
                              <Td>{entry?.bookedSatellite}</Td>
                            </TableRow>
                          )}
                          {entry?.bookedGroundStation && (
                            <TableRow>
                              <Td>Booked ground station</Td>
                              <Td>{entry?.bookedGroundStation}</Td>
                            </TableRow>
                          )}
                        </>
                      )}
                    </TableBody>
                  </Table>
                </DialogContent>
                <DialogActions>
                  <Flex alignItems="center" mx={1}>
                    <CancelButton
                      ml={1}
                      onClick={() =>
                        dispatch({
                          type: ContextActionsNames.timelineItemDialog,
                          payload: null
                        })
                      }
                    >
                      Close
                    </CancelButton>
                  </Flex>
                </DialogActions>
              </>
            );
          }}
        </SuspenseMutation>
      )}
    </Dialog>
  );
};
