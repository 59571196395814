/* eslint-disable camelcase */
import {
  Passage,
  NextPassageDuration,
  SelectedSatPassages,
  IGetRemainingTime,
  RemainingTime,
  IContext,
  OptionData,
  TimeRange
} from "app/visibilityWindow/models";
import { SatelliteInstance } from "app/satellite/models";
import moment, { Moment } from "moment";
import { extendMoment } from "moment-range";
import "moment-duration-format";
import React, { useRef, useEffect } from "react";
import { getSatelliteName } from "app/shared/utils";
import { SatellitePassages } from "../reducers";
import { isEqual } from "lodash";
import { PassageSelectorOption } from "app/visibilityWindow/components/PassagesSelector/components";
import {
  fetchPassagesAction,
  fetchNextPassagesAction,
  fetchAllPassagesAction
} from "../actions";
import { store } from "app/store";
import { getDateAtUTCOffset } from "components/TimelineRenderer/utils/helpers";

interface SelectedTimeRange {
  begin: Date;
  end: Date;
}

interface TimelineDefaulRange {
  defaultTimeStart: Moment;
  defaultTimeEnd: Moment;
}

interface ICurrNextChanged {
  current: { prevState: Passage[]; nextState: Passage[] };
  next: { prevState: Passage[]; nextState: Passage[] };
}

interface IRemainingTimeFormat {
  current: "H[H]mm[M]ss[S]";
  next: "D[d]HH[h]mm[m]ss[s]";
}

interface IUpdatePassages {
  satelliteId: number;
}

export enum Zoom {
  min = (86400 / 3) * 1000, // 6h in ms
  max = 7 * 86400 * 1000 // 7 days in ms
}

export enum InitialValue {
  begin = moment().valueOf(),
  end = moment().add(10, "hour").valueOf()
}

export const RETRY_ON_FAIL = 10000;

export const ColorOptions = [
  "orange",
  "deepskyblue",
  "#8fda47",
  "yellow",
  "#E78AC0",
  "brown",
  "#005aff",
  "#cbffaa",
  "#ce7054",
  "#BA9366",
  "#8DA0CB",
  "salmon",
  "#CB4C78",
  "#ffffbe",
  "#35b779",
  "#0d0c64",
  "#640c41",
  "#e5c494",
  "#b3b3b3",
  "#e41a1c",
  "#377eb8",
  "#ffaaba",
  "#5d518b",
  "#6bdd78",
  "#00f3ff",
  "whitesmoke",
  "#45668e",
  "#9b8998",
  "#585e69",
  "#fcd144",
  "#176720"
];

const momentExtended = extendMoment(moment as any);

export const remainingTimeFormat: IRemainingTimeFormat = {
  current: "H[H]mm[M]ss[S]",
  next: "D[d]HH[h]mm[m]ss[s]"
};

const getRemainingTime = ({
  futureTime,
  format = "H[H]mm[M]ss[S]",
  serverTime
}: IGetRemainingTime): RemainingTime => {
  const now = serverTime ? new Date(serverTime) : moment();
  const future = moment(futureTime);
  const duration = moment.duration(future.diff(now));
  const durationMinutes = duration.asMinutes();
  const durationAsSeconds = Math.floor(duration.asSeconds());
  const formatedDuration = moment
    .duration(durationMinutes, "minutes")
    .format(format);
  return {
    durationSeconds:
      (durationAsSeconds && durationAsSeconds > 0 && durationAsSeconds) || 0,
    durationMinutes:
      (durationMinutes && durationMinutes > 0 && durationMinutes) || 0,
    formatedDuration
  };
};

const getNextPassageRemainingTime = (
  passage: Passage,
  serverTime?: Date
): NextPassageDuration => {
  const now = serverTime ? new Date(serverTime) : moment();
  const beginPassage = moment(passage.aos);
  const duration = moment.duration(beginPassage.diff(now));
  const durationMinutes = duration.asMinutes();
  const formatedDuration = moment
    .duration(durationMinutes, "minutes")
    .format("D[d]HH[h]mm[m]ss[s]");
  return {
    durationMinutes: durationMinutes || 0,
    formatedDuration
  };
};

const getNextPassageDuration = (passage: Passage): NextPassageDuration => {
  const beginPassage = moment(passage.aos);
  const endPassage = moment(passage.los);
  const duration = endPassage.valueOf() - beginPassage.valueOf();
  const s = Math.floor((duration / 1000) % 60);
  const m = Math.floor((duration / 1000 / 60) % 60);
  const h = Math.floor((duration / (1000 * 60 * 60)) % 24);
  const d = Math.floor(duration / (1000 * 60 * 60 * 24));
  return {
    durationMinutes: m || 0,
    formatedDuration: `${d > 0 ? `${d}d` : ``}${h > 0 ? `${h}h` : ``}${
      m > 0 ? `${m}m` : ``
    }${s > 0 ? `${s}s` : ``}`
  };
};

const convertPassagesToTimelineFormat = (
  passages: Passage[],
  satelliteInstances: SatelliteInstance[],
  viewBySatellite: boolean,
  legendIds: number[]
) => {
  const formatedData = {
    groups: [] as any,
    items: [] as any
  };

  const groups = passages.map(
    ({ groundStationID, groundStationName, satelliteID }) => {
      return {
        id: viewBySatellite ? groundStationID : satelliteID,
        title: viewBySatellite
          ? groundStationName
          : getSatelliteName(satelliteID, satelliteInstances)
      };
    }
  );
  const uniq = {} as any;
  formatedData.groups = groups.filter(
    (obj) => !uniq[obj.id] && (uniq[obj.id] = true)
  );
  formatedData.items = passages.map(
    ({ passageID, groundStationID, aos, los, satelliteID }: Passage, index) => {
      const colorIndex = legendIds.findIndex(
        (id) => id === (viewBySatellite ? satelliteID : groundStationID)
      );
      const color: any =
        ((colorIndex || colorIndex === 0) && ColorOptions[colorIndex]) ||
        "#b1d6ed";

      return {
        id: passageID,
        group: viewBySatellite ? groundStationID : satelliteID,
        start_time: getDateAtUTCOffset(aos),
        end_time: getDateAtUTCOffset(los),
        canMove: false,
        canResize: false,
        canChangeGroup: false,
        itemProps: {
          className: "test-class--passage-item",
          style: {
            background: color,
            borderColor: color,
            overflow: "hidden",
            color
          }
        }
      };
    }
  );
  return formatedData;
};

const getServerTime = async () => {
  const res = await fetch(
    `${process.env.REACT_APP_API_URL}/notifications/time`
  );
  const now = await res.text();
  const serverTime = new Date(now);
  return serverTime;
};

export {
  getNextPassageRemainingTime,
  getNextPassageDuration,
  convertPassagesToTimelineFormat,
  getServerTime,
  getRemainingTime
};

export function useInterval(callback: () => void, delay: number | null) {
  const savedCallback = useRef<any>(null);

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

export const handleTimelineDefaultRange = ({
  begin,
  end
}: SelectedTimeRange): TimelineDefaulRange => {
  const selectedRange = momentExtended.range(begin, end);
  const defaultTimeStart = moment(begin);
  const defaultTimeEnd =
    selectedRange.duration() <= Zoom.max
      ? moment(end)
      : moment(moment(begin).add(Zoom.max, "milliseconds").toDate());
  return {
    defaultTimeStart,
    defaultTimeEnd
  };
};

/**
 * @description get curr/next passages by selected satellites
 * @returns {object}
 */
export const getCurrNext = (
  satellites: SatellitePassages,
  selectedSatId: number
): SelectedSatPassages => {
  const current =
    (satellites[selectedSatId] && satellites[selectedSatId].passages) || [];
  const next =
    (satellites[selectedSatId] && satellites[selectedSatId].nextPassages) || [];

  /**
   * Sorting current passage ASC
   */
  const _current = Array.isArray(current)
    ? current.sort((a, b) => moment(a.los).valueOf() - moment(b.los).valueOf())
    : [];

  const _next = Array.isArray(next)
    ? next.sort((a, b) => moment(a.aos).valueOf() - moment(b.aos).valueOf())
    : [];

  return { current: _current, next: _next };
};

/**
 * @description check if curr/next passages have changed checking the passageID
 * @returns {boolean}
 */
export const currNextChanged = ({
  current,
  next
}: ICurrNextChanged): boolean => {
  return (
    !isEqual(
      current.prevState.map((c) => c.passageID),
      current.nextState.map((c) => c.passageID)
    ) ||
    !isEqual(
      next.nextState.map((n) => n.passageID),
      next.prevState.map((n) => n.passageID)
    )
  );
};

export const getSelectOptions = (
  selectedSatPassages: SelectedSatPassages
): JSX.Element[] => {
  const currentPassages = (selectedSatPassages.current.length > 0 &&
    selectedSatPassages.current.map((currPassage) => (
      <PassageSelectorOption passage={currPassage} passageType={"current"} />
    ))) || [
    <option value={"none"} disabled={true}>
      No Current Passages
    </option>
  ];

  const nextPassages = selectedSatPassages.next.map((nextPassage) => (
    <PassageSelectorOption passage={nextPassage} passageType={"next"} />
  ));

  return [...currentPassages, ...nextPassages];
};

export const updatePassages = ({ satelliteId }: IUpdatePassages) => {
  const promises: Array<Promise<Passage[]>> = [];

  promises.push(
    store.dispatch(fetchPassagesAction(satelliteId)),
    store.dispatch(fetchNextPassagesAction(satelliteId))
  );
  return Promise.all(promises);
};

/**
 * @description remove the first element of the array of current|next passages
 * The passages are sorted ASC so the first passage is always the one who's going to be expired.
 */
export const clearExpiredPass = (
  passageToClear: keyof SelectedSatPassages,
  selectedSatPassages: SelectedSatPassages
): SelectedSatPassages => {
  const passageTokeep: keyof SelectedSatPassages =
    passageToClear === "current" ? "next" : "current";
  const cleared = selectedSatPassages[passageToClear].filter(
    (p) => p.passageID !== selectedSatPassages[passageToClear][0].passageID
  );
  const clearedPassages: SelectedSatPassages = { current: [], next: [] };
  clearedPassages[passageToClear] = cleared;
  clearedPassages[passageTokeep] = [...selectedSatPassages[passageTokeep]];

  return clearedPassages;
};

export const momentFormat = "YYYY-MM-DD HH:mm:ss";
export const ALL_GROUND_STATIONS = "All";
export const ALL_SATELLITES = "All";

export const getPassages = async (
  selectedSatellite: SatelliteInstance,
  options: any,
  timerange: TimeRange
) => {
  const { begin, end } = timerange;
  let satelliteIds: number[] = [];
  if (options.satellites && options.satellites.length > 0)
    satelliteIds = options.satellites;
  else satelliteIds.push(selectedSatellite.id);

  const allPassages: Passage[] = [];
  const passages = await store.dispatch(
    fetchAllPassagesAction(satelliteIds, begin as Date, end as Date)
  );
  return allPassages.concat(passages);
};

export const filterPassages = (
  passages: Passage[],
  state: IContext
): { passages: Passage[]; groundStations: string[] } => {
  const { selectedGroundStation, begin, end, filter, viewBySatellite } = state;
  const result = {
    passages: [] as Passage[],
    groundStations: [ALL_GROUND_STATIONS] as string[]
  };
  Array.isArray(passages) &&
    passages.forEach((passage: Passage) => {
      if (begin && end) {
        // The passage should fit in the begin/end range
        const calendarRange = momentExtended.range(begin, end);
        const passageRange = momentExtended.range(
          moment(passage.aos),
          moment(passage.los)
        );
        if (!passageRange.overlaps(calendarRange)) {
          return false;
        }
      }

      if (result.groundStations.indexOf(passage.groundStationName) === -1) {
        // check if the GS exists already
        result.groundStations.push(passage.groundStationName);
      }

      if (
        selectedGroundStation &&
        selectedGroundStation !== ALL_GROUND_STATIONS &&
        passage.groundStationName !== selectedGroundStation
      ) {
        // Show passage for the selected GS
        return false;
      }

      if (
        filter &&
        filter !==
          (viewBySatellite ? passage.satelliteID : passage.groundStationID)
      ) {
        // Show passages for a specific satellite
        return false;
      }

      result.passages.push(passage);
    });
  return result;
};

export const getLegendList = (
  passages: Passage[],
  satelliteInstances: SatelliteInstance[] | [],
  viewBySatellite: boolean
): OptionData[] => {
  const satSorter = (currOpt: OptionData, nextOpt: OptionData) => {
    // Sort by label
    return (
      (currOpt.label.toUpperCase() < nextOpt.label.toUpperCase() && -1) ||
      (currOpt.label.toUpperCase() > nextOpt.label.toUpperCase() && 1) ||
      0
    );
  };

  return passages
    .reduce((acc: OptionData[] | Array<any>, curr: Passage) => {
      return (
        (acc.find(
          (opt: OptionData) =>
            opt.id ===
            (viewBySatellite ? curr.satelliteID : curr.groundStationID)
        ) &&
          acc) || [
          ...acc,
          {
            id: viewBySatellite ? curr.satelliteID : curr.groundStationID,
            label: viewBySatellite
              ? getSatelliteName(curr.satelliteID, satelliteInstances)
              : curr.groundStationName
          }
        ]
      );
    }, [])
    .sort(satSorter);
};
