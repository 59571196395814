import React, { useState } from "react";
import { Form } from "app/shared";
import { Box } from "primitives";
import { SuspenseMutation } from "app/network";
import { Heading, Flex, Text } from "primitives";
import { CancelButton, SaveButton } from "components";
import { useSelector } from "react-redux";
import { exportToJson } from "utils/exportAsJson";
import {
  createExportableConfigs,
  schemaExport,
  uiSchemaExport
} from "./helpers";

export const BookingConfigExport = (props: any) => {
  const {
    bookerConfigs: { data: configs }
  } = useSelector((s: any) => s.passageManager);
  const [formData, setFormData] = useState({});
  const submit = async (
    e: React.FormEvent<HTMLInputElement>,
    formData: any
  ) => {
    const name = formData?.bookerConfigName;
    if (!name) return;
    e.preventDefault();
    const exportablePayload = createExportableConfigs(configs, name);
    if (exportablePayload) {
      exportToJson(exportablePayload, formData.bookerConfigName);
    }
    return props.history.push("/passage-manager");
  };

  return (
    <Box color="text.default" data-testid="passage-manager-export" mx={3}>
      <Flex mb={2}>
        <Heading display={1}>Passage Manager Export</Heading>
      </Flex>
      <Flex flexDirection="column" bg="fill.0" p={3}>
        <SuspenseMutation>
          {({ loading, error, action }) => {
            return (
              <Form
                formData={formData}
                schema={schemaExport(configs)}
                uiSchema={uiSchemaExport}
                disabled={loading}
                onSubmit={(
                  form: { formData: any },
                  e: React.FormEvent<HTMLInputElement>
                ) => action(() => submit(e, form.formData))}
                onChange={(form: any) => () =>
                  setFormData({ formData: form.formData })
                }
              >
                <CancelButton
                  onClick={() => props.history.push("/passage-manager")}
                  mr={2}
                  mt={4}
                >
                  Cancel
                </CancelButton>

                <SaveButton ml={2} mt={4} type="submit" disabled={loading}>
                  Export
                </SaveButton>

                {error ? (
                  <Box my={2}>
                    <Text color="text.danger">
                      Error...{console.log(error)}
                    </Text>
                  </Box>
                ) : null}
              </Form>
            );
          }}
        </SuspenseMutation>
      </Flex>
    </Box>
  );
};
