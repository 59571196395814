import { setFeedback } from "app/feedback/actions";
import { FeedbackStatus } from "app/feedback/models";
import { Dispatch } from "react";
import {
  CANCEL_BOOK_REQUEST_SUCCESS,
  CREATE_BOOKER_CONFIG_SUCCESS,
  CREATE_BOOK_REQUEST_SUCCESS,
  DELETE_BOOKER_CONFIG_SUCCESS,
  FETCH_BOOKER_CONFIGS_SUCCESS,
  FETCH_PASSAGE_CANDIDATES_SUCCESS,
  UPDATE_BOOKER_CONFIG_SUCCESS,
  SET_BOOKER_CONFIG,
  FETCH_BOOKED_AND_AVAILABLE_PASSAGES_SUCCESS,
  SET_DATE_RANGE
} from "./types";

import { status, end, NetworkState, start } from "./network";
import {
  getBookerConfigs as list,
  deleteBookerConfig as del,
  updateBookerConfig as update,
  createBookerConfig as create
} from "../services/config";
import { bookPassage, cancelPassage } from "../services/book";
import { getPassageCandidatesForConfig } from "../services/candidates";
import {
  addSatNameToGsConfig,
  findSatName,
  getSatsFromStore,
  groupBySatelliteID,
  injectNamesFromIds
} from "../utils";
import { getConstellations } from "app/constellation/services";
import { fetchAllPassages } from "app/visibilityWindow/services";
import { fetchGroundStationConfigList } from "app/groundStation";

// TODO: get this from BE
const PROVIDERS: Record<number, string> = {
  1: "LEAFSPACE",
  2: "D-ORBIT",
  3: "AWS",
  4: "ASTRALINTU",
  5: "TestGS"
};

// BOOKING
export const bookingOnePassage = (data: any, bookConfigId: any) => {
  return async (dispatch: Dispatch<any>, getState: () => any) => {
    try {
      status(start, { book: NetworkState.LOADING });
      const { data: result } = await bookPassage(data);
      dispatch({
        type: CREATE_BOOK_REQUEST_SUCCESS,
        payload: result
      });
      if (Array.isArray(result)) {
        if (result[0]?.responseCodeText === "BOOKED") {
          dispatch(setFeedback("PASSAGE BOOKED", FeedbackStatus.SUCCESS));
        } else {
          dispatch(
            setFeedback(
              "PASSAGE BOOKING FAILED",
              FeedbackStatus.ERROR,
              result[0]?.responseCodeText
            )
          );
        }
      }
      const satIds = Object.keys(
        getState()?.passageManager?.config?.groundStationsBySatelliteIDs ?? {}
      ).map(Number);

      const from = new Date();
      const to = new Date(new Date().setMonth(new Date().getMonth() + 3));
      dispatch(
        syncBookedAndAvailablePassagesAction(satIds, from, to, bookConfigId)
      );
      return Promise.resolve(result);
    } catch (e) {
      console.log("TURBO >>> bookingOnePassage", e);
      return Promise.reject(e);
    } finally {
      status(end, { sync: NetworkState.END });
    }
  };
};

export const cancelOnePassage = (data: any, bookConfig?: any) => {
  return async (dispatch: Dispatch<any>, getState: () => any) => {
    try {
      status(start, { book: NetworkState.LOADING });
      const { data: result } = await cancelPassage(data);
      dispatch({
        type: CANCEL_BOOK_REQUEST_SUCCESS,
        payload: result
      });
      if (result?.successfullyCanceled?.length > 0) {
        dispatch(
          setFeedback(
            "PASSAGE CANCELLED",
            FeedbackStatus.SUCCESS,
            result.successfullyCanceled
          )
        );
      }
      if (result?.unsuccessfullyCanceled?.length > 0) {
        dispatch(
          setFeedback(
            "PASSAGE CANCELLED FAILED",
            FeedbackStatus.ERROR,
            result.unsuccessfullyCanceled?.map(String)
          )
        );
      }
      const from = new Date();
      const to = new Date(new Date().setMonth(new Date().getMonth() + 3));
      const satIds = Object.keys(
        getState()?.passageManager?.config?.groundStationsBySatelliteIDs ?? {}
      ).map(Number);
      dispatch(
        syncBookedAndAvailablePassagesAction(satIds, from, to, bookConfig.id)
      );
      return Promise.resolve(result);
    } catch (e) {
      return Promise.reject(e);
    } finally {
      status(end, { sync: NetworkState.END });
    }
  };
};

// CANDIDATES
export const getPassageCandidates = (
  id: any,
  startTime?: string,
  endTime?: string
) => {
  return async (dispatch: Dispatch<any>, getState: () => any) => {
    try {
      dispatch({ type: SET_DATE_RANGE, payload: { startTime, endTime } });
      const satellites = getSatsFromStore(getState);
      status(start, { candidates: NetworkState.LOADING });
      const { data, ...rest } = await getPassageCandidatesForConfig(
        id,
        startTime,
        endTime
      );
      if (Array.isArray(data)) {
        const withSatNames = data.map((c: any) => {
          return {
            ...c,
            satellite: findSatName(c.satelliteID, satellites),
            provider: PROVIDERS[c.providerID]
          };
        });
        dispatch({
          type: FETCH_PASSAGE_CANDIDATES_SUCCESS,
          payload: { data: withSatNames, pagination: rest, bookingConfigId: id }
        });
        return Promise.resolve(data);
      }
      return null;
    } catch (e) {
      console.log("Error fetching candidates", e);
      return Promise.reject(null);
    } finally {
      status(end, { candidates: NetworkState.END });
    }
  };
};

// CONFIG
export const getBookerConfigs = (pagination: any = {}) => {
  return async (dispatch: Dispatch<any>, getState: () => any) => {
    try {
      let sats;
      if (getState()?.constellations?.dashboard?.length === 0) {
        sats = await getConstellations();
      }
      const groundStationConfigs = await fetchGroundStationConfigList();
      const satellites = getSatsFromStore(getState, sats);
      const configs = groupBySatelliteID(
        addSatNameToGsConfig(groundStationConfigs, satellites)
      );
      status(start, { book: NetworkState.LOADING });
      const { data, ...rest } = await list(pagination);
      dispatch({
        type: FETCH_BOOKER_CONFIGS_SUCCESS,
        payload: {
          data: injectNamesFromIds(data, satellites),
          groundStationConfigs: configs,
          pagination: rest
        }
      });
      status(end, { book: NetworkState.END });
      return Promise.resolve(data);
    } catch (e) {
      console.log(`error getting passage manager configs:`, e);
      return Promise.resolve([]);
    }
  };
};

export const createBookerConfig = (data: any) => {
  return async (dispatch: Dispatch<any>, getState: () => any) => {
    const satellites = getSatsFromStore(getState);
    try {
      const { data: result } = await create(data);
      dispatch({
        type: CREATE_BOOKER_CONFIG_SUCCESS,
        payload: injectNamesFromIds(result, satellites)
      });
      dispatch(getBookerConfigs());
      return Promise.resolve(result);
    } catch (e) {
      return Promise.resolve([]);
    }
  };
};

export const updateBookerConfig = (id: any, data: any) => {
  return async (dispatch: Dispatch<any>) => {
    try {
      const { data: result } = await update(id, data);
      dispatch({
        type: UPDATE_BOOKER_CONFIG_SUCCESS,
        payload: result
      });
      dispatch(getBookerConfigs());
      return Promise.resolve(result);
    } catch (e) {
      return Promise.resolve([]);
    }
  };
};

export const deleteBookerConfig = (data: any) => {
  return async (dispatch: Dispatch<any>) => {
    try {
      const { data: result } = await del(data);
      dispatch({
        type: DELETE_BOOKER_CONFIG_SUCCESS,
        payload: result
      });
      return Promise.resolve(result);
    } catch (e) {
      return Promise.resolve([]);
    }
  };
};

export const setConfig = (payload: any) => ({
  payload,
  type: SET_BOOKER_CONFIG
});

export const syncBookedAndAvailablePassagesAction = (
  satelliteIds: number[],
  startTime: Date,
  endTime: Date,
  bookConfigId: string | number
) => {
  return async (dispatch: Dispatch<any>, getState: () => any) => {
    const configId = bookConfigId || getState()?.passageManager?.config?.id;
    try {
      const satellites = getSatsFromStore(getState);
      status(start, { sync: NetworkState.LOADING });
      const [passagesRes, candidatesRes] = await Promise.all([
        fetchAllPassages(satelliteIds, startTime as Date, endTime as Date),
        getPassageCandidatesForConfig(configId) as Promise<any>
      ]);

      const _candidatesRes = Array.isArray(candidatesRes?.data)
        ? candidatesRes?.data
        : [];
      const candidates = _candidatesRes.map((c: any) => {
        return {
          ...c,
          satellite: findSatName(c.satelliteID, satellites),
          provider: PROVIDERS[c.providerID]
        };
      });

      const passages = (passagesRes || []).map((c: any) => {
        return {
          ...c,
          satellite: findSatName(c.satelliteID, satellites)
        };
      });

      const syncedPassagesList = [
        ...(Array.isArray(candidates) ? candidates : []),
        ...(Array.isArray(passages) ? passages : [])
      ].sort((a, b) => new Date(a.aos).getTime() - new Date(b.aos).getTime());

      dispatch({
        type: FETCH_BOOKED_AND_AVAILABLE_PASSAGES_SUCCESS,
        payload: syncedPassagesList
      });
      return Promise.resolve(syncedPassagesList);
    } catch (e) {
      console.log("Err syncBookedAndAvailablePassagesAction:", e);
      status(end, { sync: NetworkState.FAILED });
      return Promise.resolve([]);
    } finally {
      status(end, { sync: NetworkState.END });
    }
  };
};
